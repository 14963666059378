import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"campaignclients",
        "id":"campaign_client_id",
        "subid":"campaign_client_id ",
        "subidname":"campaign_client_name",
        "options":["edit","insert","delete"],
        "nameSingle":"client",
        "nameMultiple":"clients",
        "order":"campaign_client_id",
        "extraoptions":[
            {
                "name":"Campaigns",
                "page":"/campaigns",
            }
        ],


        "fields":{
            "field1":{
                "name":"Name",
                "field":"campaign_client_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Image",
                "field":"campaign_client_logo",
                "type":"ImageUpload",
                "resize":{
                    width:512,
                    height:512,
                    crop:true,
                    type:"fit",
                    quality:0.85,
                    transparant:true
                },
                "list":true,
            },
        }
    }
    
}